import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useEffect, useState } from 'react';

import { showEmptyState } from 'components/templates/BomView/EmptyStates/EmptyStates';
import {
    showConflictState,
    showErrorState,
    showMpnNotFoundState,
    showNoMpnState,
    showTooLargeData,
} from 'components/templates/iplView/ErrorState/BomIntelligenceErrorStates';
import { IplError, IplResponseBody } from 'types/ipl';

enum IplViewErrorActionType {
    SET_CLIENT_ERROR = 0,
    SET_BOM_EMPTY = 1,
    SET_MPN_NOT_FOUND = 2,
    SET_NO_MPN = 3,
    SET_EXCEEDED_SIZE = 4,
    SET_CONFLICT_STATE = 5,
    SET_NO_ERROR = 6,
}

export type EmptyStateFunction = () => EmotionJSX.Element | null;
export type EmptyStateActionFunction = () => void | null;

type IplViewErrorState = {
    isError: boolean;
    action: EmptyStateFunction;
};

const createErrorState = (
    type: IplViewErrorActionType,
    action: EmptyStateActionFunction,
    error: IplError | null = null
): IplViewErrorState => {
    switch (type) {
        case IplViewErrorActionType.SET_CLIENT_ERROR:
            return {
                isError: true,
                action: () => showErrorState(error!, action),
            };
        case IplViewErrorActionType.SET_BOM_EMPTY:
            return {
                isError: true,
                action: () => showEmptyState(),
            };
        case IplViewErrorActionType.SET_NO_MPN:
            return {
                isError: true,
                action: () => showNoMpnState(action),
            };
        case IplViewErrorActionType.SET_MPN_NOT_FOUND:
            return {
                isError: true,
                action: () => showMpnNotFoundState(action),
            };
        case IplViewErrorActionType.SET_EXCEEDED_SIZE:
            return {
                isError: true,
                action: () => showTooLargeData(action),
            };
        case IplViewErrorActionType.SET_NO_ERROR:
            return {
                isError: false,
                action: () => null,
            };
        case IplViewErrorActionType.SET_CONFLICT_STATE:
            return {
                isError: true,
                action: () => showConflictState(error!, action),
            };
        default:
            return {
                isError: true,
                action: () => showErrorState(error!, action),
            };
    }
};

const nullAction = () => {};

export function useIplViewError(
    isError: boolean,
    isLoading: boolean,
    error: IplError | null,
    data: IplResponseBody | undefined,
    reset: () => void,
    navigate: () => void,
    refetch: () => void
) {
    const initState: IplViewErrorState = {
        isError: false,
        action: () => null,
    };

    const [errorState, setErrorState] = useState(initState);

    useEffect(() => {
        if (isError === false) {
            setErrorState(createErrorState(IplViewErrorActionType.SET_NO_ERROR, nullAction, null));
            return;
        }

        const isErrorResponse =
            !error?.response || (error?.response?.status && error?.response?.status >= 500);

        const isClientError =
            error?.response?.status &&
            error?.response?.status >= 400 &&
            error?.response?.status < 500;

        if (isError && isClientError) {
            const isBomEmpty = !isLoading && error?.response?.status === 404 && !data?.data;

            const isMpnNotFound =
                error?.response?.status === 409 &&
                error.response?.data.Errors?.BOM &&
                error.response?.data.Errors?.BOM[0] === 'MPN_NOT_FOUND';

            if (
                error.response?.data.Errors?.BOM &&
                error.response?.data.Errors?.BOM[0] === 'EMPTY_MPNS'
            ) {
                setErrorState(createErrorState(IplViewErrorActionType.SET_NO_MPN, reset, null));
            } else if (isMpnNotFound) {
                setErrorState(
                    createErrorState(IplViewErrorActionType.SET_MPN_NOT_FOUND, reset, null)
                );
            } else if (isBomEmpty) {
                setErrorState(
                    createErrorState(IplViewErrorActionType.SET_BOM_EMPTY, nullAction, null)
                );
            } else if (error?.response?.status === 409) {
                setErrorState(
                    createErrorState(IplViewErrorActionType.SET_CONFLICT_STATE, reset, error)
                );
            } else if (error?.response?.status === 413) {
                setErrorState(
                    createErrorState(IplViewErrorActionType.SET_EXCEEDED_SIZE, navigate, null)
                );
            } else {
                setErrorState(
                    createErrorState(IplViewErrorActionType.SET_CLIENT_ERROR, refetch, error)
                );
            }
        } else if (isError && isErrorResponse) {
            setErrorState(
                createErrorState(IplViewErrorActionType.SET_CLIENT_ERROR, refetch, error)
            );
        }
    }, [isError, isLoading, error, data]);

    return errorState;
}
