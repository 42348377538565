import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import { useIplViewError } from 'components/templates/iplView/useIplViewError';
import useIplViewState from 'components/templates/iplView/useIplViewState';
import { ExportButtonContext } from 'providers/exportPartlistProvider/ExportPartlistProvider';
import { useIplTableData, useResetIpl } from 'services/queries/ipl.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { Path } from 'types/paths.enum';

interface IPLViewProps {
    collaborationSpaceId: string;
    projectId: string | undefined;
    revisionId: string;
    variant: string | undefined;
}

export function IPLView({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
}: IPLViewProps): JSX.Element {
    const exportContext = useContext(ExportButtonContext);
    const navigate = useNavigate();
    const [loadingIframe, setLoadingIframe] = useState(true);
    const { data, isLoading, error, isError, refetch } = useIplTableData({
        collaborationSpaceId,
        projectId,
        revisionId,
        variant,
    });

    const resetFunction = useCallback(() => {
        setErrorState(false);
        resetIpl({
            collaborationSpaceId: collaborationSpaceId,
            projectId: projectId as string,
            revisionId: revisionId,
            variant: variant,
        });
    }, [collaborationSpaceId, projectId, revisionId, variant]);

    const navigateFunction = () => {
        navigate(`${Path.PROJECTS}/${projectId}/${revisionId}/0`, { relative: 'path' });
    };

    const errorState = useIplViewError(
        isError,
        isLoading,
        error,
        data,
        resetFunction,
        navigateFunction,
        refetch
    );

    const [isErrorState, setErrorState] = useState(false);

    useEffect(() => {
        setErrorState(errorState.isError);
    }, [errorState]);

    const { resetInProgress, progressSpinnerVisible, iplVisible } = useIplViewState(
        isLoading,
        isError,
        data
    );

    const { setProgressBar } = useProgressBar();

    const { mutate: resetIpl } = useResetIpl();

    useEffect(() => {
        exportContext.setIsExportButtonVisible(false);
        exportContext.setIsExportButtonEnabled(false);
        exportContext.setOnExport(() => {});
    }, []);

    const renderProgressSpinner = useCallback(() => {
        return <>{progressSpinnerVisible && <ProgressSpinner label={LoadingStateLabel.IPL} />}</>;
    }, [progressSpinnerVisible]);

    const renderResettingProgressSpinner = useCallback(() => {
        return <>{resetInProgress && <ProgressSpinner label={LoadingStateLabel.RESETTING} />}</>;
    }, [resetInProgress]);

    const renderIplLoadingProgressSpinner = useCallback(() => {
        return <>{loadingIframe && <ProgressSpinner label={LoadingStateLabel.IPL} />}</>;
    }, [loadingIframe]);

    const renderIplView = useCallback(() => {
        return (
            <>
                {iplVisible && !resetInProgress && (
                    <Box
                        css={(theme) => ({
                            height: '100%',
                            width: '100%',
                            boxShadow: `1px 1px 1px ${theme.colors.shadow}`,
                            border: `1px solid ${theme.colors.table.border}`,
                            margin: theme.spacing(1, 0.25, 1, 0.25),
                        })}
                    >
                        <iframe
                            width='100%'
                            height='100%'
                            css={{
                                border: 'transparent',
                            }}
                            src={data?.data?.url?.url}
                            allow='fullscreen'
                            onError={() => {
                                setLoadingIframe(false);
                                setProgressBar(false);
                            }}
                            onLoad={() => {
                                setLoadingIframe(false);
                                setProgressBar(false);
                            }}
                        />
                        {renderIplLoadingProgressSpinner()}
                    </Box>
                )}
            </>
        );
    }, [iplVisible, loadingIframe, resetInProgress, data]);

    return (
        <Box
            css={(theme) => ({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(0, 0.25, 0, 0.25),
            })}
        >
            {isErrorState && errorState.action()}
            {renderProgressSpinner()}
            {renderResettingProgressSpinner()}
            {renderIplView()}
        </Box>
    );
}
