import { MutableRefObject } from 'react';

import Box from 'components/atoms/Box';
import Button, { IButton as IButtonProps } from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import VerticalMenuDivider from 'components/atoms/VerticalMenuDivider';
import { Nullable } from 'types';

export enum VerticalMenuViewType {
    GRID = 'grid',
    TABLE = 'table',
}

export enum MenuElementType {
    BUTTON = 'button',
    DIVIDER = 'divider',
}

interface IDivider {
    elementType?: MenuElementType.DIVIDER;
    id: string;
    visible?: boolean;
    ref?: MutableRefObject<Nullable<HTMLButtonElement>>;
    tooltip?: boolean;
    tooltipValue?: string;
}
interface IButton extends IButtonProps {
    elementType?: MenuElementType.BUTTON;
    id: string;
    visible?: boolean;
    ref?: MutableRefObject<Nullable<HTMLButtonElement>>;
    tooltip?: boolean;
    tooltipValue?: string;
}

export type TButtons = Array<IDivider | IButton>;

export interface IVerticalMenu {
    buttons: TButtons;
}

export const VerticalMenu = ({ buttons = [] }: IVerticalMenu) => {
    const isHiddenButtons = buttons.every(({ visible }) => visible === false);
    if (isHiddenButtons) return null;
    return (
        <Box
            css={(theme) => ({
                margin: theme.spacing(4, 0.25, 4, 0.25),
                boxShadow: 'rgb(0 0 0 / 30%) 0 1px 3px',
                padding: theme.spacing(1, 0),
            })}
        >
            {buttons.map(({ visible = true, ...button }: IDivider | IButton) => {
                if (Object.keys(button).length === 0) return;
                if (!visible) {
                    return null;
                }
                if (button.elementType === MenuElementType.DIVIDER) {
                    return <VerticalMenuDivider key={button.id}>&nbsp;</VerticalMenuDivider>;
                } else {
                    if (button.tooltip) {
                        return (
                            <Tooltip
                                title={button.tooltipValue}
                                arrow
                                enterDelay={500}
                                placement='top'
                            >
                                <Button
                                    key={button.id}
                                    {...button}
                                    css={{
                                        fontSize: '11px',
                                        lineHeight: '14px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Tooltip>
                        );
                    }
                    return (
                        <Button
                            key={button.id}
                            {...button}
                            css={{ fontSize: '11px', lineHeight: '14px', cursor: 'pointer' }}
                        />
                    );
                }
            })}
        </Box>
    );
};

export default VerticalMenu;
