import styled from '@emotion/styled';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { CcdPopupMenu } from 'components/_legacy/ccd-popup-menu';
import { CcdTable } from 'components/_legacy/ccd-table';
import Box from 'components/atoms/Box';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import SideNav from 'components/molecules/SideNav';
import useExportBom from 'components/templates/BomView/BomViewExportButton/useExportBom';
import { useRevisionsAction } from 'hooks/useRevisionsAction';
import settingsIcon from 'images/cmdSettings16.svg';
import { ExportButtonContext } from 'providers/exportPartlistProvider/ExportPartlistProvider';
import { LoadingStateLabel } from 'types/loadingStateLabel';

import BomConfigureSettingsView from '../BomConfigureSettingsView/BomConfigureSettingsView';
import BomViewTooltip from '../BomViewTooltip/BomViewTooltip';
import Events from '../CcdEvents';
import { showEmptyState, showErrorState, showInfectedState } from '../EmptyStates/EmptyStates';
import { SUPPLYFRAME_SEARCH_URL, dummyColumnName } from '../consts';
import CurrentRowContext from '../contexts/CurrentRowContext';
import { ENABLE_TOOLTIP_EVENT, REBUILD_TOOLTIP_EVENT, SHOW_SIDE_NAV_EVENT } from '../events';
import useBomData from '../useBomData';
import useBomSettings from '../useBomSettings';

import './BomView.css';

function BomView({
    collaborationspaceid,
    projectid,
    projectversion,
    iscloudenvironment,
    isRevisionInfected,
    variant,
    bomVisibility,
}) {
    const enableTooltip = useCallback(() => {
        window.dispatchEvent(new CustomEvent(ENABLE_TOOLTIP_EVENT, { detail: { flag: true } }));
    }, []);

    const disableTooltip = useCallback(() => {
        window.dispatchEvent(new CustomEvent(ENABLE_TOOLTIP_EVENT, { detail: { flag: false } }));
    }, []);

    useEffect(() => {
        const handler = (eventData) => {
            disableTooltip();
            setPartToSearch(eventData);
            setFindchipsSearchVisible(true);
            setSettingsConfigureColumnsVisible(false);
            setOpen(true);
        };

        Events.addListener(SHOW_SIDE_NAV_EVENT, handler);

        return () => {
            Events.removeListener(SHOW_SIDE_NAV_EVENT, handler);
        };
    }, [disableTooltip]);
    const { revisionsActionInProgress } = useRevisionsAction();
    const [sortDirection, setSortDirection] = useState(undefined);
    const [columnsOrder, setColumnsOrder] = useState([]);
    const [partToSearch, setPartToSearch] = useState('');
    const [currentRow, setCurrentRow] = useState(-1);
    const [settingsConfigureColumnsVisible, setSettingsConfigureColumnsVisible] = useState(false);
    const [findchipsSearchVisible, setFindchipsSearchVisible] = useState(false);
    const { ref, inView } = useInView();
    const exportContext = useContext(ExportButtonContext);
    const { exportBom, exportInProgress } = useExportBom();

    const [open, setOpen] = useState(false);
    const projectInfo = {
        collaborationSpaceId: collaborationspaceid,
        projectId: projectid,
        projectVersion: projectversion,
    };

    const isCloudEnvironmentMemo = React.useMemo(
        () => iscloudenvironment === 'true',
        [iscloudenvironment]
    );

    const provideInitialColumnsOrder = useCallback((initialColumnOrder, columns, hiddenColumns) => {
        if (!initialColumnOrder || initialColumnOrder.length === 0) {
            setColumnsOrder(columns);
            return;
        }

        const newOrderWithNewColumns = calculateInitialColumnsOrder(
            columns,
            initialColumnOrder,
            hiddenColumns
        );

        setColumnsOrder(newOrderWithNewColumns);
    }, []);

    const {
        onWrapText,
        textWrap,
        hiddenColumns,
        bindingColumn,
        bindingEnabled,
        onSettingsChange,
        onSortColumnSettingsChange,
        onOrderColumnSettingsChange,
        onColumnsWidthSettingsChange,
        initialSortSettings,
        initialColumnOrder,
        initialColumnsWidth,
        setDefaultFindchips,
        setFindchips,
        findchipsDisplayConfiguration,
        isReady: isSettingsReady,
        isFindchipsSet,
        isProjectEmpty,
        displayConfiguration,
        columnsModified,
    } = useBomSettings(projectInfo.collaborationSpaceId, projectInfo.projectId);

    useEffect(() => {
        if (initialSortSettings && !sortDirection) {
            setSortDirection({
                direction: initialSortSettings.sortDirection,
                column: { id: initialSortSettings.sortedColumnName },
            });
        }
    }, [initialSortSettings]);

    useEffect(() => {
        setColumnsOrder(initialColumnOrder);
    }, [initialColumnOrder]);

    const {
        fetchMoreData,
        columns,
        data,
        hasMore,
        dataCount,
        isBindingPresent,
        isLoaded: isBomLoaded,
        isEmpty,
        isBomDataError,
        isDataBomLoading,
        defaultColumns,
    } = useBomData(
        projectInfo.collaborationSpaceId,
        projectInfo.projectId,
        projectInfo.projectVersion,
        sortDirection,
        initialSortSettings,
        bindingColumn,
        bindingEnabled,
        isSettingsReady,
        columnsOrder,
        initialColumnOrder,
        hiddenColumns,
        provideInitialColumnsOrder,
        initialColumnsWidth,
        textWrap,
        setDefaultFindchips,
        setFindchips,
        findchipsDisplayConfiguration,
        isFindchipsSet,
        isProjectEmpty,
        variant
    );

    const defaultHiddenColumns = useMemo(() => {
        const hidden = [];
        for (const column of columns) {
            if (!defaultColumns.includes(column.Header.toLowerCase()) && column.Header !== '') {
                hidden.push(column.Header);
            }
        }
        return hidden;
    }, [defaultColumns, columns]);

    useEffect(() => {
        if (inView) {
            hasMore && fetchMoreData();
        }
    }, [inView]);

    function calculateInitialColumnsOrder(columns, initialColumnOrder, hiddenColumns) {
        const columnsWithoutHidden = columns.filter((x) => !hiddenColumns.includes(x));

        const orderColumnsCrossedWithColumns = initialColumnOrder.filter((x) =>
            columnsWithoutHidden.includes(x)
        );

        const newColumns = columnsWithoutHidden.filter(
            (x) => !orderColumnsCrossedWithColumns.includes(x)
        );

        return orderColumnsCrossedWithColumns.concat(newColumns);
    }

    useEffect(() => {
        window.dispatchEvent(new CustomEvent(REBUILD_TOOLTIP_EVENT));
    }, [currentRow]);

    const sortingHandler = (column, direction) => {
        const sortSetting = {
            column: column,
            direction: direction,
        };
        setSortDirection(sortSetting);
        onSortColumnSettingsChange(sortSetting);
    };

    const columnsOrderHandler = (orderSettings) => {
        const currentColumnsOrder = orderSettings.filter((x) => x !== dummyColumnName);
        onOrderColumnSettingsChange(currentColumnsOrder);
        setColumnsOrder(currentColumnsOrder);
    };

    const onColumnsResizeHandler = (widths) => {
        delete widths[''];
        onColumnsWidthSettingsChange(widths);
    };

    const calculateNewColumnsOrder = (order, currentlyHiddenColumns) => {
        const orderWithoutHidden = order.filter((x) => !currentlyHiddenColumns.includes(x));

        const orderWithHidden = order.concat(currentlyHiddenColumns);

        const lastingColumns = columns
            .filter((x) => !orderWithHidden.includes(x.Header) && x.Header !== '')
            .map((x) => x.Header);
        return orderWithoutHidden.concat(lastingColumns);
    };

    const settingsChange = useCallback(
        (settings) => {
            onSettingsChange(settings);
            const newColumnsOrder = calculateNewColumnsOrder(
                settings.currentlyVisibleColumns,
                settings.hiddenColumns
            );
            onOrderColumnSettingsChange(newColumnsOrder);
            setColumnsOrder(newColumnsOrder);
            setOpen((prev) => !prev);
        },
        [onSettingsChange, columns]
    );

    const onConfigureColumns = useCallback(() => {
        setFindchipsSearchVisible(false);
        setSettingsConfigureColumnsVisible(true);
        setOpen(true);
    }, [setSettingsConfigureColumnsVisible]);

    const onHoveredRow = (row) => {
        setCurrentRow(row.id);
        enableTooltip();
    };

    const SideNavContent = styled('div')(({ theme }) => ({
        padding: theme.spacing(4),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '41vw',
    }));

    const setSideNavHeader = () => {
        if (findchipsSearchVisible) {
            return 'Findchips';
        }
        if (settingsConfigureColumnsVisible) {
            return 'Configure columns';
        }
    };

    const showFindchipsPage = useCallback(() => {
        return (
            <SideNavContent>
                <iframe
                    title='FindchipsFrame'
                    src={SUPPLYFRAME_SEARCH_URL + encodeURIComponent(partToSearch)}
                    className='findchipsFrame'
                />
            </SideNavContent>
        );
    }, [partToSearch]);

    const showBom = () => (
        <CurrentRowContext.Provider value={currentRow}>
            <SideNav
                open={open}
                setOpen={setOpen}
                title={setSideNavHeader()}
                style={findchipsSearchVisible ? { width: '41vw' } : ''}
                disableClickOut={
                    settingsConfigureColumnsVisible
                        ? (e) => {
                              const walkMeBalloons = Array.from(
                                  document.querySelectorAll('[id^="walkme-balloon"]')
                              );
                              return walkMeBalloons.some((walkMeBalloon) => {
                                  return e.composedPath().includes(walkMeBalloon);
                              });
                          }
                        : undefined
                }
            >
                {findchipsSearchVisible && showFindchipsPage()}
                {settingsConfigureColumnsVisible && (
                    <BomConfigureSettingsView
                        settings={{
                            displayConfiguration,
                            columns,
                            hiddenColumns,
                            bindingEnabled,
                            bindingColumn,
                            columnsOrder,
                            defaultColumns,
                        }}
                        onConfirm={settingsChange}
                    />
                )}
            </SideNav>
            {isBindingPresent && <BomViewTooltip isCloudEnvironment={isCloudEnvironmentMemo} />}
            <CcdTable
                style={{ ...(isLoading && { display: 'none' }) }}
                columns={columns}
                data={data}
                hiddenColumns={columnsModified ? hiddenColumns : defaultHiddenColumns}
                pagination={{
                    update: fetchMoreData,
                    hasMore: hasMore,
                    component: <div ref={ref} style={{ width: '100%', height: '1px' }} />,
                }}
                onHover={onHoveredRow}
                onSorted={sortingHandler}
                onOrderChange={columnsOrderHandler}
                onColumnResize={onColumnsResizeHandler}
                settingsMenu={
                    <CcdPopupMenu
                        menuButton={settingsIcon}
                        menuItems={[
                            {
                                name: 'Configure columns',
                                callback: onConfigureColumns,
                            },
                            {
                                name: 'Wrap text',
                                callback: onWrapText,
                            },
                        ]}
                        direction='left'
                        useTableOffset
                        id='settings-table-menu'
                        closeOthers
                    />
                }
                tableSettings={{
                    wrapText: textWrap,
                    supportsDragNDrop: true,
                    initialSortSettings: initialSortSettings,
                    initialColumnOrder: columnsOrder,
                }}
            />
        </CurrentRowContext.Provider>
    );

    const isLoaded = isBomLoaded && isSettingsReady;
    const isLoading = isDataBomLoading || !isSettingsReady;
    useEffect(() => {
        if (bomVisibility === true) {
            exportContext.setIsExportButtonVisible(isLoaded);
            exportContext.setIsExportButtonEnabled(
                !revisionsActionInProgress && isLoaded && !isRevisionInfected && !exportInProgress
            );
            exportContext.setOnExport(() => {
                exportBom({
                    collaborationspaceid: projectInfo.collaborationSpaceId,
                    projectid: projectid,
                    projectversion: projectversion,
                    dataCount: dataCount,
                    sortDirection: sortDirection,
                    columnsOrder: columnsModified
                        ? columnsOrder
                        : columnsOrder.filter((x) => !defaultHiddenColumns.includes(x)),
                    variant,
                });
            });
        }
    }, [
        bomVisibility,
        projectInfo.collaborationSpaceId,
        projectid,
        projectversion,
        dataCount,
        sortDirection,
        columnsOrder,
        columnsModified,
        revisionsActionInProgress,
        isLoaded,
        exportInProgress,
    ]);

    const isInfectedStateVisible = () => {
        return isRevisionInfected;
    };

    const isErrorStateVisible = () => {
        return isBomDataError && !isLoaded && !isRevisionInfected;
    };

    const isEmptyStateVisible = () => {
        return isEmpty && !isLoaded && !isRevisionInfected;
    };

    const isBomVisible = () => {
        return !revisionsActionInProgress && isLoaded && !isRevisionInfected;
    };

    const isProgressSpinnerVisible = () => {
        return isLoading && !revisionsActionInProgress && !isRevisionInfected;
    };

    return (
        <>
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {isInfectedStateVisible() && showInfectedState()}
                {isErrorStateVisible() && showErrorState()}
                {isEmptyStateVisible() && showEmptyState()}
                {/*Do not unmount BOM on reloading!!!*/}
                {isBomVisible() && showBom()}
                {isProgressSpinnerVisible() && <ProgressSpinner label={LoadingStateLabel.BOM} />}
            </Box>
        </>
    );
}

export default BomView;
