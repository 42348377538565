import { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { ISelectList } from 'components/atoms/ComboBox';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import {
    SegmentedControl,
    SegmentedControlProps,
} from 'components/atoms/SegmentedControl/SegmentedControl';
import { SpinnerButton } from 'components/atoms/SpinnerButton/SpinnerButton';
import Tooltip from 'components/atoms/Tooltip';
import BomViewFullScreenButton from 'components/templates/BomView/BomViewFullScreenButton/BomViewFullScreenButton';
import useLocalStorage from 'hooks/useLocalStorage';
import ExportImg from 'images/cmdExport16.svg?react';
import Restore from 'images/restore.svg?react';
import { ExportButtonContext } from 'providers/exportPartlistProvider/ExportPartlistProvider';
import { ResetAnalysisContext } from 'providers/resetAnalysisStateProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import {
    useBomDataQuery,
    useBomSettingsQuery,
    useUpdateMpnColumnName,
} from 'services/queries/bom.query';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';

import BomView from '../BomView/BomView/BomView';
import { showEmptyState } from '../BomView/EmptyStates/EmptyStates';
import IPLView from '../iplView';
import ColumnMappingDialog from '../iplView/ColumnMapping/ColumnMappingDialog';
import ErrorState from '../iplView/ErrorState';
import ResetAnalysisDialog from '../iplView/ResetAnalysis/ResetAnalysisDialog';

type PartListStorageData = {
    projectId: string;
    partListType: PartListType;
    timestamp: number;
};

enum PartListType {
    BOM = 'BOM',
    IPL = 'IPL',
}

type ColumnMappingData = { bomColumns: ISelectList[] };

export default function PartList(params: {
    isCurrentRevisionLoading: boolean;
    collaborationspaceid: string;
    projectid: string | undefined;
    projectversion: string;
    iscloudenvironment: string | undefined;
    isRevisionInfected: boolean;
    bomVisibility: boolean;
    currentVariant: string | undefined;
}) {
    const { data: featureFlags } = useFeatureFlagsQuery();
    const exportContext = useContext(ExportButtonContext);
    const iplEnabled = featureFlags?.Ipl ?? false;
    const resetAnalysisContext = useContext(ResetAnalysisContext);

    const [currentView, setCurrentView] = useLocalStorage<PartListStorageData>(
        `partListView_${params.projectid}`,
        {
            partListType: PartListType.BOM,
            projectId: params.projectid as string,
            timestamp: Date.now(),
        } as PartListStorageData
    );
    const [isOpenColumnMappingDialog, setIsOpenColumnMappingDialog] = useState(false);
    const [isOpenResetAnalysisDialog, setIsOpenResetAnalysisDialog] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [mpnNotConfigured, setMpnNotConfigured] = useState(false);

    const { data: bomSettingsData, isLoading: isBomSettingsLoading } = useBomSettingsQuery({
        collaborationSpaceId: params.collaborationspaceid,
        projectId: params.projectid as string,
    });

    const { data: bomData, isLoading: isBomDataLoading } = useBomDataQuery({
        collaborationSpaceId: params.collaborationspaceid,
        projectId: params.projectid as string,
        projectVersion: params.projectversion,
        startFrom: '0',
        pageSize: '40',
        isSettingsReady: isReady,
        variant: params.currentVariant,
        mpnConfigured: !bomSettingsData?.mpnConfiguration?.mpnColumnName,
    });

    const [defaultMpnColumn, setDefaultMpnColumn] = useState<string | undefined>(
        bomData?.pages[0].defaultMpnColumn
    );

    useEffect(() => {
        setDefaultMpnColumn(bomData?.pages[0].defaultMpnColumn);
    }, [bomData]);

    useEffect(() => {
        if (!isBomSettingsLoading) {
            setIsReady(true);
        }
    }, [bomSettingsData, isBomSettingsLoading]);

    const { mutate: updateMpnColumnName } = useUpdateMpnColumnName({
        onSuccess: () => {
            setIsReady(false);
            setIsOpenColumnMappingDialog(false);
        },
    });

    const options = {
        items: [
            { name: 'BOM', value: PartListType.BOM },
            { name: 'BOM Intelligence', value: PartListType.IPL },
        ],
        defaultSelected: currentView?.partListType,
        callback: (value: string) => {
            setMpnNotConfigured(false);
            const partListView = {
                partListType: PartListType[value as keyof typeof PartListType],
                projectId: params.projectid as string,
                timestamp: Date.now(),
            } as PartListStorageData;
            setCurrentView(partListView);
        },
    } as SegmentedControlProps;

    const isBomVisible = params.bomVisibility
        ? false
        : currentView.partListType === PartListType.BOM;
    const isIplVisible = params.bomVisibility
        ? false
        : currentView.partListType === PartListType.IPL;
    const projectHasRevisions = !!params.projectversion && params.projectversion !== '0';
    const isProgressSpinnerVisible =
        isIplVisible &&
        (isBomSettingsLoading || isBomDataLoading) &&
        !bomSettingsData?.mpnConfiguration?.mpnColumnName;

    useEffect(() => {
        if (isBomVisible) {
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.viewBOM,
                analyticsConsts.Categories.versionAction,
                analyticsConsts.Sources.bomTable,
                'BOM Viewer'
            );
        }
        if (isIplVisible) {
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.viewIPL,
                analyticsConsts.Categories.versionAction,
                analyticsConsts.Sources.bomTable,
                'IPL Viewer'
            );
        }
    }, [isBomVisible, isIplVisible]);

    const bomDataColumns = bomData?.pages[0].columns.map((column) => ({
        label: column,
        value: column,
    })) as ISelectList[];

    const ColumnMappingDialogComponent = ({ bomColumns }: ColumnMappingData) => (
        <>
            {
                <ColumnMappingDialog
                    defaultMpn={defaultMpnColumn}
                    bomColumns={bomColumns}
                    open={isOpenColumnMappingDialog}
                    setOpen={setIsOpenColumnMappingDialog}
                    onConfirm={(selectedMpnColumn: string) => {
                        setDefaultMpnColumn(selectedMpnColumn);
                        setMpnNotConfigured(false);
                        updateMpnColumnName({
                            collaborationSpaceId: params.collaborationspaceid,
                            projectId: params.projectid as string,
                            mpnColumnName: selectedMpnColumn,
                        });
                    }}
                    onClose={() => {
                        setIsOpenColumnMappingDialog(false);
                        setMpnNotConfigured(true);
                    }}
                />
            }
        </>
    );

    const ResetAnalysisDialogComponent = () => (
        <>
            {
                <ResetAnalysisDialog
                    collaborationSpaceId={params.collaborationspaceid}
                    projectId={params.projectid as string}
                    revisionId={params.projectversion}
                    variant={params.currentVariant}
                    open={isOpenResetAnalysisDialog}
                    setOpen={setIsOpenResetAnalysisDialog}
                    onConfirm={() => {
                        setIsOpenResetAnalysisDialog(false);
                    }}
                    onClose={() => {
                        setIsOpenResetAnalysisDialog(false);
                    }}
                />
            }
        </>
    );

    useEffect(() => {
        if (
            currentView.partListType === PartListType.IPL &&
            !bomSettingsData?.mpnConfiguration?.mpnColumnName
        ) {
            setIsOpenColumnMappingDialog(true);
        }
    }, [currentView, bomSettingsData]);

    const putExportButton = useMemo(() => {
        return exportContext.isExportButtonVisible ? (
            <>
                <Tooltip
                    title={
                        exportContext.isExportButtonEnabled
                            ? 'Export Bill of Materials to your local drive'
                            : 'Export in progress, please wait'
                    }
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <span>
                        <SpinnerButton
                            id={'exportBom'}
                            color={'gray'}
                            css={{ cursor: 'pointer' }}
                            disabled={!exportContext.isExportButtonEnabled}
                            onClick={exportContext.onExport}
                            isLoading={!exportContext.isExportButtonEnabled}
                            variant='text'
                            leftIcon={exportContext.isExportButtonEnabled ? ExportImg : undefined}
                        >
                            {exportContext.isExportButtonEnabled ? 'Export BOM' : 'Exporting...'}
                        </SpinnerButton>
                    </span>
                </Tooltip>
            </>
        ) : null;
    }, [exportContext.isExportButtonVisible, exportContext.onExport]);

    const putResetAnalysisButton = (
        mpnColumnName: string | undefined,
        isIplVisible: boolean,
        isResetAnalysisVisible: boolean,
        isResetAnalysisInProgress: boolean,
        openCallback: () => void
    ) => {
        const isButtonResetAnalysisVisible =
            isIplVisible && mpnColumnName && isResetAnalysisVisible && !isResetAnalysisInProgress;
        return isButtonResetAnalysisVisible ? (
            <>
                <Tooltip
                    title='Reset BOM Intelligence analysis'
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <Button
                        variant={'text'}
                        color={'gray'}
                        id='reset-analysis'
                        css={{
                            cursor: isResetAnalysisInProgress ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                            openCallback();
                        }}
                        leftIcon={Restore}
                    >
                        Reset Analysis
                    </Button>
                </Tooltip>
            </>
        ) : null;
    };

    const showMpnConfigurationDialog = () => {
        return (
            isIplVisible &&
            !bomSettingsData?.mpnConfiguration?.mpnColumnName &&
            bomDataColumns?.length > 0 && (
                <ColumnMappingDialogComponent bomColumns={bomDataColumns} />
            )
        );
    };

    const showIplEmptyState = () => {
        return (
            isIplVisible &&
            !isProgressSpinnerVisible &&
            !bomSettingsData?.mpnConfiguration?.mpnColumnName &&
            !bomData &&
            showBomEmptyState()
        );
    };

    const showMpnNotConfigured = () => {
        return (
            isIplVisible &&
            mpnNotConfigured &&
            !isProgressSpinnerVisible &&
            bomData && (
                <ErrorState>
                    <Paragraph>
                        For BOM Intelligence to work properly, you need to define the column with
                        your part list.
                    </Paragraph>
                    <Button
                        onClick={() => {
                            setMpnNotConfigured(false);
                            setIsOpenColumnMappingDialog(true);
                        }}
                    >
                        Configure
                    </Button>
                </ErrorState>
            )
        );
    };

    const showPartList = () => {
        return (
            <Fragment>
                <Box
                    css={(theme) => ({
                        margin: theme.spacing(4, 0.25, 0, 0.25),
                        boxShadow: theme.colors.boxShadow,
                        display: 'inline-block',
                        ...(params.bomVisibility && { display: 'none' }),
                    })}
                >
                    <BomViewFullScreenButton />
                    {putExportButton}
                    {putResetAnalysisButton(
                        bomSettingsData?.mpnConfiguration?.mpnColumnName,
                        isIplVisible,
                        resetAnalysisContext.isResetAnalysisVisible,
                        resetAnalysisContext.isResetAnalysisInProgress,
                        () => {
                            setIsOpenResetAnalysisDialog(true);
                        }
                    )}

                    {iplEnabled ? <SegmentedControl {...options} /> : null}
                </Box>
                {isIplVisible && bomSettingsData?.mpnConfiguration?.mpnColumnName && (
                    <Box
                        css={(theme) => ({
                            marginTop: theme.spacing(4, 0.25, 4, 0.25),
                            height: '100%',
                            boxShadow: 'rgb(0 0 0 / 30%) 0 1px 3px',
                            display: 'inline-block',
                            paddingTop: '1rem',
                        })}
                    >
                        <IPLView
                            collaborationSpaceId={params.collaborationspaceid}
                            projectId={params.projectid}
                            revisionId={params.projectversion}
                            variant={params.currentVariant}
                        />
                        <ResetAnalysisDialogComponent />
                    </Box>
                )}
                <Box
                    css={(theme) => ({
                        marginTop: theme.spacing(4, 0, 4, 0),
                        height: '100%',
                        display: isBomVisible ? 'inline-block' : 'none',
                        overflowX: 'auto',
                        paddingTop: '1rem',
                    })}
                >
                    <BomView
                        bomVisibility={isBomVisible}
                        collaborationspaceid={params.collaborationspaceid}
                        projectid={params.projectid}
                        projectversion={params.projectversion}
                        iscloudenvironment={params.iscloudenvironment}
                        isRevisionInfected={params.isRevisionInfected}
                        variant={params.currentVariant}
                    />
                </Box>
                {isProgressSpinnerVisible && (
                    <Box
                        css={(theme) => ({
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing(0, 0.25, 0, 0.25),
                        })}
                    >
                        <ProgressSpinner label={LoadingStateLabel.IPL} />
                    </Box>
                )}
                {showMpnConfigurationDialog()}
                {showMpnNotConfigured()}
                {showIplEmptyState()}
            </Fragment>
        );
    };

    const showBomEmptyState = () => {
        return (
            <Box
                css={(theme) => ({
                    marginTop: theme.spacing(4, 0, 4, 0),
                    height: '100%',
                    display: params.bomVisibility ? 'none' : 'inline-block',
                    overflowX: 'auto',
                    paddingTop: '1rem',
                })}
            >
                {showEmptyState()}
            </Box>
        );
    };

    return projectHasRevisions || params.isCurrentRevisionLoading
        ? showPartList()
        : showBomEmptyState();
}
